






























import { defineComponent, PropOptions } from "vue";
import { YandexMapAddressItemInterface } from "@/models/yandex-map/YandexMapAddressItem.interface";
import { YandexMapSuggestionItemInterface } from "@/models/yandex-map/YandexMapSuggestionItem.interface";
import YandexMapDialog from "@/components/yandex-map-suggestion/YandexMapDialog.vue";
import YandexMapSuggestionInput from "@/components/yandex-map-suggestion/YandexMapSuggestionInput.vue";

export default defineComponent({
  name: "YandexMapSuggestion",
  components: { YandexMapSuggestionInput, YandexMapDialog },
  props: {
    value: {
      type: Object,
      required: true
    } as PropOptions<YandexMapAddressItemInterface>,
    label: {
      type: String,
      required: true
    } as PropOptions<string>,
    error: {
      type: Boolean,
      default: false
    } as PropOptions<boolean>,
    errorMessages: {
      type: [String, Array],
      default: ""
    } as PropOptions<string | string[]>,
    addressesPrompt: {
      type: Array,
      default: () => [] as YandexMapAddressItemInterface[]
    } as PropOptions<YandexMapAddressItemInterface[]>,
    mapLabel: {
      type: String,
      default: ""
    } as PropOptions<string>,
    prompt: {
      type: String,
      default: ""
    } as PropOptions<string>
  },
  data() {
    return {
      search: "",
      items: [] as YandexMapSuggestionItemInterface[],
      isLoading: false,
      showDialog: false
    };
  },
  methods: {
    onMapClick(): void {
      this.showDialog = true;
    },
    onDialogClose(): void {
      this.showDialog = false;
    },
    async onInput(address: YandexMapAddressItemInterface) {
      this.$emit("input", address);
    }
  }
});
