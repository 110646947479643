/**
 * Return ru-locale date string.
 * @param {Date, String, Number} date
 * @return {string}
 */
export default function getLocaleDateString(
  date: string | Date | number
): string {
  const computedDate = new Date(date);

  if (computedDate.toString() === "Invalid Date") return "";

  return computedDate.toLocaleDateString("ru-RU");
}
