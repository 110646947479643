
import { defineComponent, PropOptions } from "vue";
import { YandexMapAddressItemInterface } from "@/models/yandex-map/YandexMapAddressItem.interface";
import ymaps, { ymaps as yMapsTypes } from "ymaps";
import { YandexMapSuggestionItemInterface } from "@/models/yandex-map/YandexMapSuggestionItem.interface";
import { YandexMapService } from "@/models/yandex-map/YandexMap.service";

export default defineComponent({
  name: "YandexMapSuggestionInput",
  props: {
    value: {
      type: Object,
      required: true
    } as PropOptions<YandexMapAddressItemInterface>,
    label: {
      type: String,
      required: true
    } as PropOptions<string>,
    error: {
      type: Boolean,
      default: false
    } as PropOptions<boolean>,
    errorMessages: {
      type: [String, Array],
      default: ""
    } as PropOptions<string | string[]>,
    addressesPrompt: {
      type: Array,
      default: () => [] as YandexMapAddressItemInterface[]
    } as PropOptions<YandexMapAddressItemInterface[]>,
    prompt: {
      type: String,
      required: true
    } as PropOptions<string>
  },
  data() {
    return {
      search: "",
      items: [] as YandexMapSuggestionItemInterface[],
      isLoading: false,
      ymaps: null as typeof yMapsTypes | null,
      errorMessage: ""
    };
  },
  watch: {
    search: "onSearch",
    value: {
      handler(value: YandexMapAddressItemInterface | null) {
        this.search = value?.value ?? "";
      },
      deep: true
    }
  },
  mounted(): void {
    ymaps
      .load(
        "https://api-maps.yandex.ru/2.1/?apikey=736f8e29-4276-4cda-b223-d0c31c283d76&lang=ru-RU"
      )
      .then(map => {
        this.ymaps = map;
      });
  },
  methods: {
    async onSearch(value?: string): Promise<void> {
      if (!value || value.trim().length < 3 || this.ymaps == null) return;
      this.isLoading = true;

      try {
        this.items = await this.ymaps.suggest(value);

        this.isLoading = false;
      } catch (e) {
        this.errorMessage = e.message;
      }
    },
    async setItem(item: YandexMapSuggestionItemInterface): Promise<void> {
      const searchedItems = await YandexMapService.geocode(item.value);

      if (searchedItems.length === 0) {
        return;
      }

      this.$emit("input", searchedItems[0]);
    },
    async onBlur(): Promise<void> {
      if (!this.search) {
        this.$emit("input", null);
        return;
      }

      if (this.search === this.value?.value) return;

      const searchedItems = await YandexMapService.geocode(this.search);

      if (searchedItems.length === 0) {
        this.$emit("input", null);
        return;
      }

      this.$emit("input", searchedItems[0]);
    }
  }
});
