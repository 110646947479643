import { ContactItemInterface } from "@/models/order/ContactItem.interface";
import { Ref } from "vue";

export function useContactMain(contact: Ref<ContactItemInterface>) {
  function setContact(value: string | ContactItemInterface) {
    if (typeof value === "string") {
      contact.value.fullName = value;
      return;
    }
    contact.value.countryCode = value.countryCode;
    contact.value.fullName = value.fullName;
    contact.value.phone = value.phone;
  }
  function changeCountry(country: string) {
    contact.value.countryCode = country;
    contact.value.phone = "";
  }

  return {
    setContact,
    changeCountry
  };
}
