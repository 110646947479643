


















































































import ContactItem from "@/components/order/contacts/ContactItem.vue";
import PeriodDateItem from "@/components/order/PeriodDateItem.vue";
import { ContactItemInterface } from "@/models/order/ContactItem.interface";
import PersonItemFtl from "@/components/order/PersonItemFtl.vue";
import { OrganizationTypeEnum } from "@/models/autocomplete/OrganizationType.enum";
import { getAddress, getContacts } from "@/api/autocomplete";
import { AddressTypeEnum } from "@/models/autocomplete/AddressType.enum";
import {
  computed,
  defineComponent,
  inject,
  PropType,
  Ref,
  ref,
  toRef
} from "vue";
import {
  AddressObjectInterface,
  LoadingAddressObjectInterface
} from "@/models/order/AddressObject.interface";
import useVuelidate from "@vuelidate/core";
import ContactItemModel from "@/models/order/ContactItem.model";
import { addressItemRules } from "@/validations/order/addressItemRules";
import InputMask from "@/components/UI/InputMask.vue";
import YandexMapSuggestion from "@/components/yandex-map-suggestion/YandexMapSuggestion.vue";
import { YandexMapAddressItemModel } from "@/models/yandex-map/YandexMapAddressItem.model";
import { YandexMapAddressItemInterface } from "@/models/yandex-map/YandexMapAddressItem.interface";
import { PeriodItemInterface } from "@/models/order/PeriodItem.interface";

export default defineComponent({
  name: "AddressItemFtl",
  components: {
    YandexMapSuggestion,
    InputMask,
    PersonItemFtl,
    ContactItem,
    PeriodDateItem
  },
  props: {
    addressObj: {
      type: Object as PropType<
        AddressObjectInterface | LoadingAddressObjectInterface
      >,
      required: true
    },
    loadingAddress: {
      type: String,
      default: ""
    },
    loadingObg: {
      type: [Object, null] as PropType<PeriodItemInterface | null>,
      default: () => ({})
    },
    showRemove: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    addressLabel: {
      type: String as PropType<string>,
      default: "Адрес"
    },
    contactsLabel: {
      type: String as PropType<string>,
      default: "Контакты"
    },
    personLabel: {
      type: String as PropType<string>,
      default: "Человек"
    },
    header: {
      type: String as PropType<string>,
      default: "Погрузка"
    },
    personType: {
      type: [String, Number] as PropType<OrganizationTypeEnum>,
      default: OrganizationTypeEnum.Sender
    },
    showPlaceCount: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    isUnloadingType: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },
  emits: ["remove", "addContact", "removeContact"],
  setup(props) {
    const addressObj = toRef(props, "addressObj");
    const loadingAddress = toRef(props, "loadingAddress");
    const isUnloadingType = toRef(props, "isUnloadingType");
    const addressData = computed(() => addressObj.value.address.data);
    const rules = computed(() => {
      return addressItemRules(addressData, loadingAddress, isUnloadingType);
    });
    const hasEdm: Ref<boolean> = inject("hasEdm") ?? ref(false);

    const v$ = useVuelidate(rules, addressObj);
    const isEqualAddresses = computed(() => {
      return (
        isUnloadingType.value &&
        loadingAddress.value === addressData.value.fullAddress
      );
    });

    return {
      v$,
      isEqualAddresses,
      hasEdm
    };
  },
  data() {
    return {
      addressesPrompt: [] as YandexMapAddressItemInterface[],
      contactsPrompt: [] as ContactItemInterface[],
      previousAddress: null as YandexMapAddressItemInterface | null,
      currentAddress: null as YandexMapAddressItemInterface | null,
      mapOpened: false as boolean,
      mapComponentKey: 0 as number
    };
  },
  watch: {
    "addressObj.person.id"(id: number): void {
      this._updateAddressesPrompt(id);
      this._updateContactsPrompt(id);
    }
  },
  mounted(): void {
    this.previousAddress = { ...this.addressObj.address };
  },
  methods: {
    updateInn(): void {
      this.setAddress(new YandexMapAddressItemModel());
    },
    addContact(): void {
      this.$emit("addContact");
    },
    removeContact(contactIdx: number): void {
      this.$emit("removeContact", contactIdx);
    },
    async _updateAddressesPrompt(id: number): Promise<void> {
      const res = await getAddress(
        id,
        this.personType === OrganizationTypeEnum.Sender
          ? AddressTypeEnum.Loading
          : AddressTypeEnum.Unloading
      );

      if (!res.isSuccess) {
        this.addressesPrompt = [];
        return;
      }

      this.addressesPrompt = res.entity.map(address => {
        const addressItem = new YandexMapAddressItemModel();

        addressItem.value = address.fullAddress;

        return addressItem;
      });
    },
    async _updateContactsPrompt(id: number): Promise<void> {
      const res = await getContacts(id);

      if (!res.isSuccess) {
        this.contactsPrompt = [];
        return;
      }

      this.contactsPrompt = res.entity.map(contact => {
        const contactItem = new ContactItemModel();

        contactItem.fullName = `${contact.lastName} ${contact.firstName} ${contact.middleName}`;
        contactItem.phone = contact.phone;
        contactItem.countryCode = contact.countryCode;
        return contactItem;
      });
    },
    setAddress(address: YandexMapAddressItemInterface | null) {
      this.currentAddress = address;
      this.addressObj.setAddress(address);

      if (!this.mapOpened) {
        this.previousAddress = address;
      }
    },
    abortMapAddress() {
      if (this.previousAddress) {
        this.addressObj.setAddress(this.previousAddress);
      }

      this.mapComponentKey++;
    },
    saveMapAddress() {
      this.previousAddress = this.currentAddress;
    }
  }
});
