import useVuelidate from "@vuelidate/core";
import { computed, Ref, watch } from "vue";
import { OrganizationTypeEnum } from "@/models/autocomplete/OrganizationType.enum";
import { personItemRules } from "@/validations/personItem/personItemRules";
import { PersonItemInterface } from "@/models/person/PersonItem.interface";

export function usePersonValidation(
  person: Ref<PersonItemInterface>,
  organizationType: OrganizationTypeEnum,
  hasEdm?: Ref<boolean>
) {
  const rules = computed(() => {
    return personItemRules(person, organizationType, hasEdm);
  });

  const v$ = useVuelidate(rules, person);

  watch(
    () => person.value.type,
    () => {
      v$.value.$touch();
    }
  );

  return {
    v$
  };
}
