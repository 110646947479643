import { OrderItemInterface } from "@/models/order/OrderItem.interface";
import { computed, ref } from "vue";
export const addedZero = (num: string) => {
  return num.padStart(2, "0");
};

export const useDurationTime = (order: OrderItemInterface) => {
  const auctionDuration = ref("");

  const mask = computed(() => {
    return [
      {
        mask: "",
        lazy: true
      },
      {
        mask: "0 мин",
        lazy: false
      },
      {
        mask: "00 мин",
        lazy: false
      },
      {
        mask: "0 ч 00 мин",
        lazy: false
      },
      {
        mask: "00 ч 00 мин",
        lazy: false
      },
      {
        mask: "0 дн 00 ч 00 мин",
        lazy: false
      },
      {
        mask: "00 дн 00 ч 00 мин",
        lazy: false
      },
      {
        mask: "000 дн 00 ч 00 мин",
        lazy: false
      }
    ];
  });
  if (order.auctionTime) {
    if (order.auctionTime) {
      const [h, m] = order.auctionTime.split(":");
      const finalHours = parseInt(h) + ~~(parseInt(m) / 60);
      if (finalHours >= 23999) {
        auctionDuration.value = "9992359";
      } else {
        const gays = String(~~(finalHours / 24));
        const hour = String(finalHours % 24);
        const minute = String(parseInt(m) % 60);

        auctionDuration.value = `${gays}${addedZero(hour)}${addedZero(minute)}`;
      }
    }
  }

  return { auctionDuration, mask };
};
