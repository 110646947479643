




































import BaseInput from "@/components/UI/BaseInput.vue";
import getLocaleDateString from "@/utils/getLocaleDateString";
import { defineComponent, PropType } from "vue";
import { BaseValidation, ErrorObject } from "@vuelidate/core";

export default defineComponent({
  name: "DatePicker",
  components: { BaseInput },
  props: {
    label: {
      type: String as PropType<string>,
      default: ""
    },
    value: {
      type: String as PropType<string>,
      required: true
    },
    meta: {
      type: Object as PropType<BaseValidation>,
      default: () => ({} as BaseValidation)
    },
    min: {
      type: String as PropType<string>,
      default: ""
    },
    max: {
      type: String as PropType<string>,
      default: ""
    },
    isRequired: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },
  data() {
    return {
      showDatepicker: false
    };
  },
  computed: {
    localeDateString(): string {
      if (!this.value) return "";

      return getLocaleDateString(this.value);
    },
    errorMessages(): string[] {
      return this.meta.$errors.map((error: ErrorObject) => error.$message);
    }
  },
  methods: {
    onBlur(): void {
      if (!this.meta) return;

      this.meta.$touch();
    },
    showDatePicker(): void {
      this.showDatepicker = true;
    }
  }
});
