import { helpers, required, requiredIf } from "@vuelidate/validators";
import { OrganizationTypeEnum } from "@/models/autocomplete/OrganizationType.enum";
import { PersonType } from "@/models/person/Person.interface";
import { Ref } from "vue";
import { PersonItemInterface } from "@/models/person/PersonItem.interface";
import { twoWordsInName } from "@/validations/person";

export function personItemRules(
  person: Ref<PersonItemInterface>,
  organizationType: OrganizationTypeEnum,
  hasEdm?: Ref<boolean>
) {
  const individualOrEntrepreneurRequiredValidation = requiredIf(function() {
    if (
      organizationType === OrganizationTypeEnum.Loader ||
      organizationType === OrganizationTypeEnum.LoadingOwner
    ) {
      return (
        !!hasEdm?.value &&
        (person.value.type === PersonType.INDIVIDUAL ||
          person.value.type === PersonType.ENTREPRENEUR)
      );
    }

    return (
      person.value.type === PersonType.INDIVIDUAL ||
      person.value.type === PersonType.ENTREPRENEUR
    );
  });

  const companyRequiredValidation = requiredIf(function() {
    if (
      organizationType === OrganizationTypeEnum.Loader ||
      organizationType === OrganizationTypeEnum.LoadingOwner
    ) {
      return !!hasEdm?.value && person.value.type === PersonType.COMPANY;
    }

    return person.value.type === PersonType.COMPANY;
  });

  return {
    id: {},
    countryCode: {},
    clearData: {},
    getFullName: {},
    updateData: {},
    type: {
      required
    },
    fullName: {
      required: individualOrEntrepreneurRequiredValidation,
      twoWordsInName
    },
    firstName: {},
    middleName: {},
    lastName: {},
    name: {
      required: companyRequiredValidation
    },
    inn: {
      required: requiredIf(function(): boolean {
        if (
          organizationType === OrganizationTypeEnum.Loader ||
          organizationType === OrganizationTypeEnum.LoadingOwner
        ) {
          return !!hasEdm?.value;
        }

        if (person.value.type === PersonType.INDIVIDUAL) return !!hasEdm?.value;

        return (
          person.value.type === PersonType.COMPANY ||
          person.value.type === PersonType.ENTREPRENEUR
        );
      }),
      min: (value: unknown): boolean => {
        const strValue = value as string;

        if (!helpers.req(value)) return true;

        if (person.value.countryCode !== "RUS") {
          if (
            person.value.type !== PersonType.COMPANY &&
            person.value.type !== PersonType.ENTREPRENEUR
          ) {
            return true;
          }
        } else {
          if (
            !hasEdm?.value &&
            person.value.type !== PersonType.COMPANY &&
            person.value.type !== PersonType.ENTREPRENEUR
          )
            return true;
        }

        if (person.value.type === PersonType.COMPANY) {
          switch (person.value.countryCode) {
            case "RUS":
              return strValue.length === 10;
            case "KAZ":
              return strValue.length === 12;
            case "UZB":
              return strValue.length === 9;
            default:
              return true;
          }
        }

        switch (person.value.countryCode) {
          case "RUS":
          case "KAZ":
            return strValue.length === 12;
          case "UZB":
            return strValue.length === 14;
          default:
            return true;
        }
      }
    },
    ogrn: {
      required: requiredIf(() => {
        if (!hasEdm?.value) return false;

        if (
          organizationType === OrganizationTypeEnum.Loader ||
          organizationType === OrganizationTypeEnum.LoadingOwner
        ) {
          return (
            !!hasEdm?.value &&
            person.value.countryCode === "RUS" &&
            (person.value.type === PersonType.COMPANY ||
              person.value.type === PersonType.ENTREPRENEUR)
          );
        }

        return (
          person.value.countryCode === "RUS" &&
          (person.value.type === PersonType.COMPANY ||
            person.value.type === PersonType.ENTREPRENEUR)
        );
      }),
      min: (value: string): boolean => {
        if (!helpers.req(value)) return true;

        return person.value.type === PersonType.ENTREPRENEUR
          ? value.length === 15
          : value.length === 13;
      }
    },
    kpp: {
      required: requiredIf(() => {
        if (!hasEdm?.value) return false;

        if (
          organizationType === OrganizationTypeEnum.Loader ||
          organizationType === OrganizationTypeEnum.LoadingOwner
        ) {
          return (
            !!hasEdm?.value &&
            person.value.countryCode === "RUS" &&
            person.value.type === PersonType.COMPANY
          );
        }

        return (
          person.value.countryCode === "RUS" &&
          person.value.type === PersonType.COMPANY
        );
      }),
      min: (value: string): boolean => {
        if (!helpers.req(value)) return true;

        return value.length === 9;
      }
    },
    address: {
      required: requiredIf(() => {
        if (!hasEdm?.value || person.value.countryCode !== "RUS") return false;

        return true;
      })
    }
  };
}
