




























































































































































import BaseInput from "@/components/UI/BaseInput.vue";
import { OrganizationTypeEnum } from "@/models/autocomplete/OrganizationType.enum";
import {
  ComputedRef,
  defineComponent,
  inject,
  PropType,
  ref,
  Ref,
  toRef
} from "vue";
import { useTaxTypes } from "@/components/personItem/useTaxTypes";
import { usePersonValidation } from "@/components/personItem/usePersonValidation";
import { PersonItemInterface } from "@/models/person/PersonItem.interface";
import OrderService from "@/models/order/Order.service";
import { usePersonInnFieldViewConfig } from "@/components/personItem/usePersonInnFieldViewConfig";
import CountrySelect from "@/components/order/city/CountrySelect.vue";
import InputMask from "@/components/UI/InputMask.vue";
import fullNameMask from "@/mixins/fullNameMask";
import { usePersonSearch } from "@/components/personItem/usePersonSearch";
import { usePersonMain } from "@/components/personItem/usePersonMain";

export default defineComponent({
  name: "PersonItem",
  components: {
    InputMask,
    CountrySelect,
    BaseInput
  },
  props: {
    person: {
      type: Object as PropType<PersonItemInterface>,
      required: true
    },
    type: {
      type: [String, Number] as PropType<OrganizationTypeEnum>,
      default: OrganizationTypeEnum.Customer
    }
  },
  setup(props) {
    const _organizationType = toRef(props, "type");
    const _person: Ref<PersonItemInterface> = toRef(props, "person");
    const hasEdm: Ref<boolean> = inject("hasEdm") ?? ref<boolean>(false);
    const edmAvailable: ComputedRef<boolean> | undefined = inject(
      "edmAvailable"
    );

    const { taxesTypes, getTaxType } = useTaxTypes();
    const {
      selectedPerson,
      isDefaultPlaceOwner,
      changePersonType,
      setCountryCode,
      setInn
    } = usePersonMain(_person, _organizationType, taxesTypes);
    const {
      returnLabelForInn,
      returnMaskForInn
    } = usePersonInnFieldViewConfig();
    const {
      onOrgSearch,
      personList,
      searchQuery,
      isSearchLoading
    } = usePersonSearch(_person, _organizationType);
    const { v$ } = usePersonValidation(
      _person,
      _organizationType.value,
      hasEdm
    );

    return {
      v$,
      taxesTypes,
      fullNameMask,
      searchQuery,
      personList,
      isDefaultPlaceOwner,
      selectedPerson,
      hasEdm,
      isSearchLoading,
      edmAvailable,
      getTaxType,
      setInn,
      changePersonType,
      returnLabelForInn,
      returnMaskForInn,
      onOrgSearch,
      setCountryCode
    };
  },
  methods: {
    toggleDefaultPlaceOwner() {
      if (this.isDefaultPlaceOwner)
        OrderService.postDefaultPlaceOwner(this.person);
    }
  }
});
