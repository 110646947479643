import { computed, ComputedRef, onMounted, ref, Ref } from "vue";
import { PersonItemInterface } from "@/models/person/PersonItem.interface";
import PersonService from "@/models/person/Person.service";
import PersonItemModel from "@/models/person/PersonItem.model";
import { PersonType } from "@/models/person/Person.interface";
import { OrganizationInterface } from "@/models/store/Organization.interface";
import { OrganizationTypeEnum } from "@/models/autocomplete/OrganizationType.enum";
import OrderService from "@/models/order/Order.service";

export interface PersonListItemInterface extends OrganizationInterface {
  value: string;
}

export function usePersonMain(
  personValue: Ref<PersonItemInterface>,
  type: Ref<OrganizationTypeEnum>,
  taxesTypes: ComputedRef<{ text: string; value: PersonType }[]>
) {
  const isDefaultPlaceOwner = ref<boolean>(false);
  const selectedPerson = computed<PersonListItemInterface>(() => {
    return {
      ...personValue.value,
      value: personValue.value.inn + personValue.value.kpp
    } as PersonListItemInterface;
  });
  const setInn = async (value: PersonItemInterface | null | string) => {
    if (typeof value === "string") {
      await PersonService.updatePerson(
        personValue.value,
        {
          ...personValue.value,
          inn: value
        },
        type.value
      );
      return;
    }

    await PersonService.updatePerson(
      personValue.value,
      value ?? new PersonItemModel(),
      type.value
    );
  };
  const changePersonType = async (type: PersonType) => {
    await PersonService.changePersonType(personValue.value, type);
  };
  const setCountryCode = async (countryCode: string) => {
    await PersonService.setPersonCountryCode(personValue.value, countryCode);
    await changePersonType(taxesTypes.value[0].value);
  };

  onMounted(() => {
    if (type.value !== OrganizationTypeEnum.Customer) return;

    if (
      ((personValue.value.type === PersonType.ENTREPRENEUR ||
        personValue.value.type === PersonType.COMPANY) &&
        !!personValue.value.inn) ||
      (personValue.value.type === PersonType.INDIVIDUAL &&
        !!personValue.value.lastName)
    )
      return;

    OrderService.getDefaultPlaceOwner().then(person => {
      if (person == null) return;

      return PersonService.updatePerson(
        personValue.value,
        person as PersonItemInterface,
        type.value
      );
    });
  });

  return {
    selectedPerson,
    isDefaultPlaceOwner,
    setInn,
    changePersonType,
    setCountryCode
  };
}
