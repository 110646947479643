import { infotekaApi as api } from "@/api/baseApi";
import { ListItemInterface } from "@/models/global/ListItem.interface";
import { AxiosResponse } from "axios";
import { CountryInterface } from "@/models/global/CountryInterface";
import { ContainerTypesInterface } from "@/models/infoteka/containerTypes.interface";
import { LoadTypesInterface } from "@/models/infoteka/loadTypes.interface";
import { DangerousCargoItemInterface } from "@/models/order/DangerousCargoItem.interface";

export async function getCargoCategories(): Promise<ListItemInterface[]> {
  const res: AxiosResponse<ListItemInterface[]> = await api.post(
    "/ProductCategories"
  );

  return res.data;
}

export async function getBodyTypes(): Promise<ListItemInterface[]> {
  const res: AxiosResponse<ListItemInterface[]> = await api.post(
    "/CarBodyTypes"
  );

  return res.data;
}

export async function getLoadingType(): Promise<ListItemInterface[]> {
  const res: AxiosResponse<ListItemInterface[]> = await api.post(
    "/UnloadingTypes"
  );

  return res.data;
}

export async function getVatRateTypes(): Promise<ListItemInterface[]> {
  const res: AxiosResponse<ListItemInterface[]> = await api.post(
    "/VatRatesTypes"
  );

  return res.data;
}

export async function getCountries(): Promise<CountryInterface[]> {
  const res: AxiosResponse<CountryInterface[]> = await api.post(
    "/CountryCodes"
  );
  return res.data;
}

export async function getContainerTypes(): Promise<ContainerTypesInterface[]> {
  const res: AxiosResponse<ContainerTypesInterface[]> = await api.post(
    "/ContainerTypes"
  );

  return res.data;
}

export async function getLoadTypes(): Promise<LoadTypesInterface[]> {
  const res: AxiosResponse<LoadTypesInterface[]> = await api.post("/LoadTypes");

  return res.data;
}

export async function getDangerousCargos(): Promise<
  DangerousCargoItemInterface[]
> {
  const res: AxiosResponse<DangerousCargoItemInterface[]> = await api.post(
    "/DangerousLoads"
  );

  return res.data;
}
