import { DimensionsFormInterface } from "@/models/order/DimensionsForm.interface";
import OrderService from "@/models/order/Order.service";
import { OrderServiceInterface } from "@/models/order/Order.service.interface";
import { OrderItemInterface } from "@/models/order/OrderItem.interface";
import { Ref } from "vue";

export function useOrderFieldsInput(form: Ref<OrderItemInterface>) {
  const _orderService: OrderServiceInterface = new OrderService();

  const onDimensionsInput = ({
    dimensionName,
    value
  }: {
    dimensionName: keyof DimensionsFormInterface;
    value: string;
  }) => {
    _orderService.setDimensionToOrder(form.value, dimensionName, value);
  };
  const onPlaceDimensionsInput = (data: {
    placeIdx: number;
    dimensionName: keyof DimensionsFormInterface;
    value: string;
  }) => {
    _orderService.setDimensionToOrderPlace(
      form.value,
      data.placeIdx,
      data.dimensionName,
      data.value
    );
  };
  const onAssessedValueInput = (value: string) => {
    _orderService.setAssessedValueToOrder(form.value, value);
  };
  const onPriceInput = (value: string) => {
    _orderService.setPriceToOrder(form.value, value);
  };
  const onPlaceAssessedValueInput = (placeIdx: number, value: string) => {
    _orderService.setAssessedValueToOrderPlace(form.value, placeIdx, value);
  };

  return {
    onDimensionsInput,
    onPlaceDimensionsInput,
    onAssessedValueInput,
    onPlaceAssessedValueInput,
    onPriceInput
  };
}
