<template>
  <div ref="chipsWrapper" class="selectable-chips-wrapper">
    <v-chip
      v-for="item in displayedItems"
      :key="item.id"
      :input-value="!!selectedItems.find(i => i.id === item.id)"
      :value="item"
      outlined
      filter
      color="secondary"
      text-color="secondary"
      @click="toggleSelection(item)"
    >
      {{ item.title }}
    </v-chip>
    <v-chip
      v-if="showMoreButton"
      outlined
      color="secondary"
      text-color="secondary"
      :ripple="false"
      @click="toggleShowAll"
    >
      {{ showAll ? "Показать меньше" : "Ещё группы" }}
    </v-chip>
    <template v-if="items.length === 0">
      <slot name="no-data"></slot>
    </template>
  </div>
</template>

<script>
import { defineComponent, nextTick } from "vue";

export default defineComponent({
  name: "SelectableChips",
  props: {
    items: {
      type: Array,
      required: true
    },
    selectedItems: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      showAll: false,
      maxItemsInRow: 3
    };
  },
  computed: {
    displayedItems() {
      return this.showAll
        ? this.items
        : this.items.slice(0, this.maxItemsInRow);
    },
    showMoreButton() {
      return this.items.length > this.maxItemsInRow;
    }
  },
  watch: {
    items() {
      this.calculateMaxItemsInRow();
    },
    selectedItems(newVal) {
      this.emitSelectedItems(newVal);
    }
  },
  mounted() {
    this.calculateMaxItemsInRow();
    window.addEventListener("resize", this.calculateMaxItemsInRow);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.calculateMaxItemsInRow);
  },
  methods: {
    toggleShowAll() {
      this.showAll = !this.showAll;
    },
    emitSelectedItems(selectedItems) {
      this.$emit("update:selected-chips", selectedItems);
    },
    toggleSelection(item) {
      const selected = !this.selectedItems.includes(item);
      this.$emit("update:selected-chip", { item, selected });
    },
    estimateChipWidth(title) {
      const averageCharWidth = 8;
      const padding = 24;
      return title.length * averageCharWidth + padding;
    },
    calculateMaxItemsInRow() {
      nextTick(() => {
        const wrapper = this.$refs.chipsWrapper;
        if (wrapper) {
          const wrapperWidth = wrapper.clientWidth;
          let totalWidth = 140;
          let count = 0;
          for (const item of this.items) {
            const chipWidth = this.estimateChipWidth(item.title) + 10;
            if (totalWidth + chipWidth <= wrapperWidth) {
              totalWidth += chipWidth;
              count++;
            } else {
              break;
            }
          }

          this.maxItemsInRow = count;
        }
      });
    }
  }
});
</script>

<style scoped>
.selectable-chips-wrapper {
  margin: 5px 0;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
</style>
