import { required } from "@vuelidate/validators";
import convertStringToNumber from "@/utils/convertStringToNumber";

export function dimensionsRules() {
  return {
    volume: {
      required,
      minValue(value: unknown) {
        return convertStringToNumber(value as string) > 0;
      }
    },
    weight: {
      required,
      minValue(value: unknown) {
        return convertStringToNumber(value as string) > 0;
      }
    }
  };
}
