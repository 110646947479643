import useVuelidate from "@vuelidate/core";
import { requiredIf } from "@vuelidate/validators";
import { Ref } from "vue";
import { ContactItemInterface } from "@/models/order/ContactItem.interface";

export function useContactValidation(
  contact: Ref<ContactItemInterface>,
  isRequired: Ref<boolean>
) {
  const v$ = useVuelidate(
    {
      countryCode: {
        required: requiredIf(() => isRequired.value)
      },
      fullName: {
        required: requiredIf(() => isRequired.value)
      },
      phone: {
        required: requiredIf(() => isRequired.value)
      }
    },
    contact
  );

  return {
    v$
  };
}
