


















































































import InputMask from "@/components/UI/InputMask.vue";
import { DimensionsFormInterface } from "@/models/order/DimensionsForm.interface";
import { computed, defineComponent, PropType, toRefs } from "vue";
import useVuelidate from "@vuelidate/core";
import { dimensionsRules } from "@/validations/order/dimensionsRules";

export default defineComponent({
  name: "Dimensions",
  components: { InputMask },
  props: {
    title: {
      type: String as PropType<string>,
      default: "Характеристики груза"
    },
    dimensions: {
      type: Object as PropType<DimensionsFormInterface>,
      required: true
    },
    readonlyFields: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },
  emits: ["calcVolume", "update:dimension"],
  setup(props, { emit }) {
    const { dimensions, readonlyFields } = toRefs(props);
    const rules = computed(() => {
      return dimensionsRules();
    });

    const v$ = useVuelidate(rules, dimensions);

    const onDimensionsInput = (
      dimensionName: keyof DimensionsFormInterface,
      value: string
    ) => {
      if (readonlyFields.value) return;

      emit(`update:dimension`, { dimensionName, value });
    };

    return {
      v$,
      onDimensionsInput
    };
  }
});
