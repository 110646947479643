import { helpers, minValue, required, requiredIf } from "@vuelidate/validators";
import { YandexMapAddressItemDataInterface } from "@/models/yandex-map/YandexMapAddressItemData.interface";
import { ComputedRef, Ref } from "vue";

export function addressItemRules(
  addressData: ComputedRef<YandexMapAddressItemDataInterface | null>,
  loadingAddress: Ref<string>,
  isUnloadingType: Ref<boolean>
) {
  return {
    count: {
      required,
      min: minValue(1)
    },
    address: {
      value: {
        required
      },
      data: {
        required,
        locality: {
          required: helpers.withMessage(
            "Адрес должен был заполнен до города",
            requiredIf(() => {
              return (
                addressData.value != null &&
                !addressData.value.street &&
                !addressData.value.house
              );
            })
          )
        }
      }
    },
    contacts: {
      required
    }
  };
}
