/**
 * Маска для поля ввода ФИО
 *
 * Mask for input if full name
 */
import { computed } from "vue";

export default computed(() => {
  return /^([-A-Za-zА-Яа-яЁё]+( ?)){0,4}$/;
});
