import {
  helpers,
  maxLength,
  minLength,
  required,
  requiredIf
} from "@vuelidate/validators";
import { isTime } from "@/validations/dateTimeValidations";
import { startOfDay, addMinutes } from "date-fns";
import { Ref } from "vue";
import { parseDateString, parseTimeToMinute } from "@/utils/dateUtils";
import { PeriodItemInterface } from "@/models/order/PeriodItem.interface";

export function periodDateItemRules(
  isUnloadingType: Ref<boolean>,
  loadingDateObj: Ref<PeriodItemInterface | null>,
  period: Ref<PeriodItemInterface>,
  isInterval: Ref<boolean>
) {
  return {
    dateFrom: {
      required,
      minDate(value: unknown) {
        const valueAsDate = parseDateString(value as string);

        if (valueAsDate == null) return { $valid: true };

        if (isUnloadingType.value && loadingDateObj.value != null) {
          const loadingDate = parseDateString(
            loadingDateObj.value.isInterval
              ? loadingDateObj.value.dateTo
              : loadingDateObj.value.dateFrom
          );

          if (loadingDate != null && loadingDate > valueAsDate) {
            return {
              $valid: false,
              message: "Дата выгрузки не должна быть раньше даты погрузки"
            };
          }
        }

        return valueAsDate >= startOfDay(new Date())
          ? {
              $valid: true
            }
          : {
              $valid: false,
              message: "Дата не должна быть раньше сегодняшней"
            };
      }
    },
    dateTo: {
      required: requiredIf(isInterval),
      minDate(value: unknown, model: PeriodItemInterface) {
        if (!helpers.req()) return { $valid: true };

        const valueAsDate = parseDateString(value as string);
        const dateFromDate = parseDateString(model.dateFrom);

        if (valueAsDate == null || dateFromDate == null) {
          return { $valid: true };
        }

        return valueAsDate >= dateFromDate
          ? {
              $valid: true
            }
          : {
              $valid: false,
              message: "Дата до не должна быть раньше даты от"
            };
      }
    },
    hourFrom: {
      required,
      min: minLength(5),
      max: maxLength(5),
      isTime,
      minValue(value: string) {
        const dateValue = parseDateString(period.value.dateFrom);
        const hourFromMinuteValue = parseTimeToMinute(value);

        if (dateValue == null) return { $valid: true };
        if (!isUnloadingType.value) return { $valid: true };

        if (loadingDateObj.value != null) {
          const loadingDateValue = parseDateString(
            loadingDateObj.value.dateFrom
          );
          const loadingTimeValue = parseTimeToMinute(
            loadingDateObj.value.isInterval
              ? loadingDateObj.value.hourTo
              : loadingDateObj.value.hourFrom
          );

          if (loadingDateValue == null) return { $valid: true };

          const loadingDateTime = addMinutes(
            loadingDateValue,
            loadingTimeValue + 15
          );

          const unloadingDateTime = addMinutes(dateValue, hourFromMinuteValue);
          return loadingDateTime <= unloadingDateTime
            ? { $valid: true }
            : {
                $valid: false,
                message:
                  "Дата погрузки должна быть на 15 минут или ранее даты выгрузки"
              };
        }

        return { $valid: true };
      }
    },
    hourTo: {
      required: requiredIf(isInterval),
      min: minLength(5),
      max: maxLength(5),
      isTime,
      minValue(value: string) {
        if (!helpers.req(value) || !isInterval.value) return { $valid: true };

        const dateFrom = parseDateString(period.value.dateFrom);
        const dateTo = parseDateString(period.value.dateTo);

        if (dateFrom == null || dateTo == null || dateTo > dateFrom) {
          return { $valid: true };
        }

        const hourFromMinuteValue = parseTimeToMinute(period.value.hourFrom);
        const hourToMinuteValue = parseTimeToMinute(value);

        const dateTimeFrom = addMinutes(dateFrom, hourFromMinuteValue);
        const dateTimeTo = addMinutes(dateTo, hourToMinuteValue);

        return dateTimeFrom <= dateTimeTo
          ? { $valid: true }
          : {
              $valid: false,
              message: "Время до должно быть позже времени от"
            };
      }
    }
  };
}
