import { computed, Ref } from "vue";
import { ContactItemInterface } from "@/models/order/ContactItem.interface";

export function usePhoneMask(contact: Ref<ContactItemInterface>) {
  const phoneMask = computed<string | null>(() => {
    switch (contact.value.countryCode) {
      case "UZB":
        return "{+998} 00 000-0000";
      case "RUS":
      case "KAZ":
        return "{+7} 000 000-00-00";
      default:
        return null;
    }
  });

  return {
    phoneMask
  };
}
