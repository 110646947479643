











































import {
  defineComponent,
  nextTick,
  onMounted,
  PropType,
  ref,
  toRef
} from "vue";
import { OrderItemInterface } from "@/models/order/OrderItem.interface";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { getExecutorOrganizations } from "@/api/order";
import {
  ExecutorOrganizationInterface,
  OrganizationViewInterface
} from "@/models/global/OrganizationView.interface";

export default defineComponent({
  props: {
    order: {
      type: Object as PropType<OrderItemInterface>,
      default: () => ({})
    }
  },
  setup(props, { emit }) {
    const order = toRef(props, "order");
    const v$ = useVuelidate(
      {
        executorOrganization: {
          required
        }
      },
      order
    );
    const organizationsIsLoading = ref<boolean>(true);
    const organizations = ref<ExecutorOrganizationInterface[]>([]);
    const isDisabled = ref<boolean>(false);

    const getOrganizations = async () => {
      const res = await getExecutorOrganizations();
      organizationsIsLoading.value = false;

      if (!res.isSuccess) {
        organizations.value = [];
        return;
      }

      organizations.value = res.entity;
      await nextTick();
      if (organizations.value.length === 1) {
        isDisabled.value = true;
        props.order.executorOrganization = organizations.value[0];

        if (props.order.executorOrganization) {
          emit(
            "setOrganizationCountry",
            props.order.executorOrganization.countryCode
          );
        }
      }
    };

    const setOrganization = (organization: OrganizationViewInterface) => {
      emit("setOrganizationCountry", organization.countryCode);
    };

    onMounted(() => {
      getOrganizations();
    });

    return {
      v$,
      organizations,
      setOrganization,
      isDisabled,
      organizationsIsLoading
    };
  }
});
