import { Ref, ref } from "vue";
import { getCountries } from "@/api/infoteka";
import { CountryInterface } from "@/models/global/CountryInterface";

export default () => {
  const isLoading = ref(true);
  const cites: Ref<CountryInterface[]> = ref([]);

  const reqCountries = [
    "Белоруссия",
    "Казахстан",
    "Узбекистан",
    "Киргизия",
    "Россия"
  ];

  getCountries().then(data => {
    cites.value = data.filter(
      item => reqCountries.includes(item.title) && !item.isHidden
    );
    isLoading.value = false;
  });

  return {
    isLoading,
    countries: cites
  };
};
