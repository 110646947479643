import { PersonItemInterface } from "@/models/person/PersonItem.interface";
import PersonService from "@/models/person/Person.service";
import { computed, onMounted, Ref, ref, watch } from "vue";
import { OrganizationInterface } from "@/models/store/Organization.interface";
import { OrganizationTypeEnum } from "@/models/autocomplete/OrganizationType.enum";
import { PersonListItemInterface } from "@/components/personItem/usePersonMain";

import { useAutocompleteStore } from "@/store/useAutocompleteStore";
import { PersonType } from "@/models/person/Person.interface";

export function usePersonSearch(
  person: Ref<PersonItemInterface>,
  type: Ref<OrganizationTypeEnum>
) {
  const personsByInnSuggestion = ref<OrganizationInterface[]>([]);
  let _abortController: AbortController | null = null;
  const searchQuery = ref("");
  const isSearchLoading = ref(false);
  const personList = ref<PersonListItemInterface[]>([]);
  const autocompleteStore = useAutocompleteStore();

  const orgFilter = (
    org: OrganizationInterface,
    query: string,
    type: PersonType
  ): boolean => {
    if (!query) return true;

    return (
      (org.type === type &&
        (!query?.trim() ||
          org.inn?.includes(query.toLowerCase().trim()) ||
          org.name?.toLowerCase().includes(query.toLowerCase().trim()) ||
          org.lastName?.toLowerCase().includes(query.toLowerCase().trim()) ||
          org.firstName?.toLowerCase().includes(query.toLowerCase().trim()) ||
          org.middleName
            ?.toLowerCase()
            .includes(query.toLowerCase().trim()))) ??
      false
    );
  };
  const personListAutocomplete = computed((): OrganizationInterface[] => {
    let personListName:
      | "customerList"
      | "senderList"
      | "recipientList"
      | "loaderList"
      | "loadingOwnerList";

    switch (type.value) {
      case OrganizationTypeEnum.Customer:
        personListName = "customerList";
        break;
      case OrganizationTypeEnum.Sender:
        personListName = "senderList";
        break;
      case OrganizationTypeEnum.Recipient:
        personListName = "recipientList";
        break;
      case OrganizationTypeEnum.Loader:
        personListName = "loaderList";
        break;
      case OrganizationTypeEnum.LoadingOwner:
        personListName = "loadingOwnerList";
        break;
      default:
        personListName = "loadingOwnerList";
    }

    const personList = autocompleteStore[
      personListName
    ] as OrganizationInterface[];

    return personList.filter(organization => {
      return organization.type === person.value.type;
    });
  });
  const onOrgSearch = async (query: string): Promise<void> => {
    _abortController?.abort();
    personList.value = [];
    searchQuery.value = query;
    isSearchLoading.value = false;

    const filteredPersonsAutocomplete: OrganizationInterface[] = personListAutocomplete.value.filter(
      x => orgFilter(x, searchQuery.value, person.value.type)
    );

    if (filteredPersonsAutocomplete.length > 0) {
      personList.value = filteredPersonsAutocomplete.map(x => {
        return {
          ...x,
          value: `${x.inn}-${x.kpp}-${x.ogrn}`
        };
      });
      return;
    }

    if (person.value.countryCode !== "RUS") return;

    isSearchLoading.value = true;
    _abortController = new AbortController();
    const suggestion = await PersonService.searchPersonsByInn(
      query,
      _abortController.signal
    );
    personList.value = suggestion.map(org => {
      return {
        ...org,
        value: `${org.inn}-${org.kpp}-${org.ogrn}`
      };
    });
    isSearchLoading.value = false;
  };

  watch(personListAutocomplete, () => {
    onOrgSearch(searchQuery.value);
  });
  watch(
    () => person.value.type,
    () => {
      searchQuery.value = "";
      onOrgSearch(searchQuery.value);
    }
  );

  onMounted(() => {
    onOrgSearch(searchQuery.value);
  });

  return {
    personsByInnSuggestion,
    searchQuery,
    personList,
    personListAutocomplete,
    isSearchLoading,
    orgFilter,
    onOrgSearch
  };
}
