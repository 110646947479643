

























import { defineComponent, PropType, onMounted, watch, ref } from "vue";
import SelectableChips from "@/components/UI/SelectableChips.vue";
import { OrganizationGroupInterface } from "@/models/OrganizationGroup/OrganizationGroup.interface";
import {
  getOrganizationGroupForOrganization,
  getOrganizationsToForwardOrder
} from "@/api/order";
import {
  ExecutorRoleTypeEnum,
  OrganizationViewInterface
} from "@/models/global/OrganizationView.interface";
import { OrderItemInterface } from "@/models/order/OrderItem.interface";

export default defineComponent({
  name: "OrganizationGroups",
  components: { SelectableChips },
  props: {
    order: {
      type: Object as PropType<OrderItemInterface>,
      required: true
    },
    onSelectGroup: {
      type: Function as PropType<(group: OrganizationGroupInterface) => void>,
      required: true
    },
    organizationGroups: {
      type: Array as PropType<OrganizationGroupInterface[]>,
      required: true
    },
    selectedOrganizationGroups: {
      type: Array as PropType<OrganizationGroupInterface[]>,
      required: true
    }
  },
  emits: ["update-organization-groups", "update-selected-groups"],
  setup(props, { emit }) {
    const initPartners = ref<OrganizationViewInterface[]>([]);

    const initGroups = () => {
      const initPartnersGroup = {
        id: "only-partners",
        title: "Все партнеры",
        organizations: initPartners.value
      };

      const initPartnersGroupIsExist = props.organizationGroups.find(
        (o: OrganizationGroupInterface) => o.id === initPartnersGroup.id
      );

      if (initPartnersGroupIsExist && initPartners.value.length === 0) {
        emit(
          "update-organization-groups",
          props.organizationGroups.filter(
            (o: OrganizationGroupInterface) => o.id !== initPartnersGroup.id
          )
        );
      }

      if (!initPartnersGroupIsExist && initPartners.value.length !== 0) {
        emit("update-organization-groups", [
          initPartnersGroup,
          ...props.organizationGroups
        ]);
      }
    };

    const loadGroups = async (orgId: string) => {
      const res = await getOrganizationGroupForOrganization(
        ExecutorRoleTypeEnum.Delivery,
        orgId,
        false
      );
      if (!res.isSuccess) return;
      emit("update-organization-groups", res.entity ?? []);
    };

    const loadPartners = async (orgId: string) => {
      const res = await getOrganizationsToForwardOrder(
        ExecutorRoleTypeEnum.Delivery,
        orgId,
        "",
        true
      );

      if (!res.isSuccess) return;

      initPartners.value = res.entity;
    };

    const onUpdateSelectedGroups = (
      selectedItems: OrganizationGroupInterface[]
    ) => {
      emit("update-selected-groups", selectedItems);
    };

    onMounted(async () => {
      if (props.order.executorOrganization !== null) {
        await loadPartners(props.order.executorOrganization.id);
        await loadGroups(props.order.executorOrganization.id);
        initGroups();
      }
    });

    watch(
      () => props.order.executorOrganization,
      async org => {
        if (org !== null) {
          await loadPartners(org.id);
          await loadGroups(org.id);
          initGroups();
        }
      }
    );

    return {
      initPartners,
      loadGroups,
      loadPartners,
      initGroups,
      onUpdateSelectedGroups
    };
  }
});
